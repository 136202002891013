import http from '@/config/axios'
import Vue from "vue";

export default {
    state: {
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') || [],
        user_id: localStorage.getItem('user_id') || '',
    },
    getters: {
        isLoggedIn: state => !!state.token,
        user: state => state.user,
        user_id: state => state.user_id
    },
    actions: {
        async startAuth({commit}, data) {
            http.post('/auth/login', data).then((res) => {
                if (res.data.result !== 'error') {
                    commit('SET_USER', res.data.data);
                    window.location.href = '/';
                } else {
                    Vue.$toast.error(res.data.message);
                }
            })
        },
        async logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('user_id');
            window.location.href = '/auth';
        },
    },
    mutations: {
        SET_USER(state, data) {
            state.token = data.token
            localStorage.setItem('token', data.token)

            state.user = data.full_name
            localStorage.setItem('user', data.full_name)

            state.user_id = data.id
            localStorage.setItem('user_id', data.id)
        },
    },
}