import Vue from 'vue'
import Vuex from 'vuex'
import index from './modules/index/index'
import auth from './modules/auth/auth';
import users from './modules/users/users';
import category from "./modules/category/category";
import products from "./modules/products/products";
import orders from './modules/orders/orders';
import banners from './modules/banners/banners';
import callCenter from "./modules/call/call";
import settings from './modules/settings/settings';
import promocodes from './modules/promocodes/promocodes';
import sms from './modules/sms/sms';
import profile from './modules/profile/profile';
import mailing from './modules/mailing/mailing';
import slots from './modules/stores/stores';
import multibonus from './modules/multibonus/multibonus';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        modal: {
            treeCategory: false,
            createPromocode: false,
            editPromocode: false,
            viewCall: false,
            createCategory: false,
            createSubCategory: false,
            sendSms: false,
            sendWhatsApp: false,
            createUser: false,
            editUser: false,
            mbCreateCategory: false,
            selectProducts: false,
        },
    },
    getters: {
        isLoading: state => state.loading,
    },
    actions: {
        startLoading (ctx) {
            ctx.commit('SET_LOADING', true)
        },
        stopLoading (ctx) {
            ctx.commit('SET_LOADING', false)
        },
        openModal(ctx, name){
            ctx.commit('updateModalVisible', name);
        },
    },
    mutations: {
        SET_LOADING (state, status) {
            state.loading = status
        },
        updateModalVisible(state, name){
            this.state.modal[name] = true;
        }
    },
    modules: {
        auth, index, users, category, products, orders, banners, callCenter, settings, promocodes, sms, profile, mailing, slots, multibonus
    }
});
