import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/auth',
            name: 'login',
            component: () => import('@/views/auth/login.vue'),
            meta: {
                layout: 'empty',
                requiresAuth: false,
            }
        },
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/index.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/list-users',
            name: 'list-users',
            component: () => import('@/views/users/list-users.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/view-user/:id',
            name: 'view-user',
            component: () => import('@/views/users/view-user.vue'),
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/list-question',
            name: 'list-question',
            component: () => import('@/views/users/list-question.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/list-products',
            name: 'list-products',
            component: () => import('@/views/products/list-product.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/view-product/:id',
            name: 'view-product',
            component: () => import('@/views/products/view-product.vue'),
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-orders',
            name: 'list-orders',
            component: () => import('@/views/orders/list-order.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/create-order',
            name: 'create-order',
            component: () => import('@/views/orders/create-order.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/view-order/:id',
            name: 'view-order',
            component: () => import('@/views/orders/view-order.vue'),
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-banners',
            name: 'list-banners',
            component: () => import('@/views/banners/list-banners.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/create-banner',
            name: 'create-banner',
            component: () => import('@/views/banners/create-banner.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/edit-banner/:id',
            name: 'edit-banner',
            component: () => import('@/views/banners/edit-banner.vue'),
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-call',
            name: 'call',
            component: () => import('@/views/call/list-call.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/call-stats',
            name: 'call-stats',
            component: () => import('@/views/call/list-call-stats.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/screen-call',
            name: 'screen-call',
            component: () => import('@/views/call/screen-call.vue'),
            meta: {
                layout: 'free',
                requiresAuth: true
            },
        },
        {
            path: '/screen-analytics',
            name: 'screen-analytics',
            component: () => import('@/views/call/screen-analytics.vue'),
            meta: {
                layout: 'free',
                requiresAuth: true
            },
        },
        {
            path: '/list-category',
            name: 'list-category',
            component: () => import('@/views/category/list-category.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/view-category/:id',
            name: 'view-category',
            component: () => import('@/views/category/view-category.vue'),
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/edit-category/:id',
            name: 'edit-category',
            component: () => import('@/views/category/edit-category.vue'),
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/edit-sub-category/:id',
            name: 'edit-sub-category',
            component: () => import('@/views/category/edit-sub-category.vue'),
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/views/settings/settings.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/promocodes',
            name: 'promocodes',
            component: () => import('@/views/promocodes/promocodes.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-mailing',
            name: 'list-mailing',
            component: () => import('@/views/mailing/list-mailing.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/mailing/create',
            name: 'mailing-create',
            component: () => import('@/views/mailing/maillingCreate.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-letter',
            name: 'list-letter',
            component: () => import('@/views/mailing/list-letter.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-letter-template',
            name: 'list-letter-template',
            component: () => import('@/views/mailing/list-letter-template.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/designer-letter/:id?',
            name: 'designer-letter',
            props: true,
            component: () => import('@/views/mailing/designer-letter.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/designer-letter-template/:id?',
            name: 'designer-letter-template',
            props: true,
            component: () => import('@/views/mailing/designer-letter-template.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/designer-mailing/:id?',
            name: 'designer-mailing',
            props: true,
            component: () => import('@/views/mailing/designer-mailing.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/profile/profile.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/profile/settings',
            name: 'profile-settings',
            component: () => import('@/views/profile/settings.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/multibonus/list',
            name: 'listCategoryMB',
            component: () => import('@/views/multibonus/list-category.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/multibonus/products',
            name: 'listProductsMB',
            component: () => import('@/views/multibonus/list-products.vue'),
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (window.callScreenInterval) {
        clearInterval(window.callScreenInterval)
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/auth')
    } else {
        next()
    }
});

export default router
