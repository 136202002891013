import http from '@/config/axios'
import Vue from "vue";
import store from "../../index";

export default {
    actions: {
        async getCategories(ctx) {
            const res = await http.get('/category/list-categories');
            ctx.commit('updateCategoryInfo', res.data.data);
        },
        async getOzonCategories(ctx) {
            const res = await http.get('/category/ozon-categories');
            ctx.commit('updateOzonCategories', res.data.data);
        },
        async getCategoriesInfo(ctx, id) {
            const res = await http.get('/category/get-category?id=' + id);
            ctx.commit('updateCategory', res.data.data);
        },
        async getSubCategoriesInfo(ctx, id) {
            const res = await http.get('/category/get-sub-category?id=' + id);
            ctx.commit('updateSubCategory', res.data.data);
        },
        async createCategory(ctx, data) {
            await http.post('/category/create-category', data).then(async res => {
                if (res.data.result == 'ok') {
                    store.state.modal.createCategory = false;
                    ctx.commit('updateCategoryInfo', res.data.data);
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editCategory(ctx, data) {
            let formData = new FormData();
            formData.append('id', data.id);
            formData.append('name', data.name);
            formData.append('title_browser', data.title_browser);
            formData.append('imageWeb', data.imageWeb);
            formData.append('imageMobBlack', data.imageMobBlack);
            formData.append('imageMobWhite', data.imageMobWhite);
            formData.append('desc', data.desc);
            formData.append('meta', data.meta);
            formData.append('url', data.url);
            formData.append('sort', data.sort);
            formData.append('ozon_category_id', data.ozon_category_id);
            await http.post('/category/edit-category', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editCategorySort(ctx, data) {
            await http.post('/category/edit-sort-category', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async createSubCategory(ctx, data) {
            await http.post('/category/create-sub-category', data).then(async res => {
                if (res.data.result == 'ok') {
                    store.state.modal.createSubCategory = false;
                    ctx.commit('updateCategoryInfo', res.data.data);
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editSubCategory(ctx, data) {
            await http.post('/category/edit-sub-category', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editSubCategorySort(ctx, data) {
            await http.post('/category/edit-sort-sub-category', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editSubCategoriesSort(ctx, data) {
            await http.post('/category/edit-sort-sub-categories', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async editProductsCategoriesSort(ctx, data) {
            await http.post('/category/edit-sort-categories-products', data).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async changeActiveCategory(ctx, id) {
            await http.get('/category/change-active-category?id=' + id).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        },
        async changeActiveSubCategory(ctx, id) {
            await http.get('/category/change-active-sub-category?id=' + id).then(async res => {
                if (res.data.result == 'ok') {
                    Vue.$toast.success(res.data.message);
                } else {
                    Vue.$toast.error(res.data.message);
                }
            });
        }
    },
    mutations: {
        updateCategoryInfo(state, data) {
            state.masterCategory = data.category;
            state.subCategory = data.subCategory;
        },
        updateOzonCategories(state, data) {
            state.ozonCategories = data
        },
        updateCategory(state, data) {
            state.category = data;
        },
        updateSubCategory(state, data) {
            state.sCategory = data;
        }
    },
    state: {
        category: [],
        sCategory: [],
        masterCategory: [],
        subCategory: [],
        ozonCategories: [],
    },
    getters: {
        categoryInfo: state => state.masterCategory,
        category: state => state.category,
        sCategory: state => state.sCategory,
        subCategoryInfo: state => state.subCategory,
        ozonCategories: state => state.ozonCategories,
    }
}