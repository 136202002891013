import axios from 'axios'
import store from '@/store/index'
import router from '@/router'
import Vue from 'vue'

const baseURL = process.env.VUE_APP_API_URL
const http = axios.create({ baseURL })

let pendingRequests = 0

http.interceptors.request.use(
    async (config) => {
        if (config.showLoader !== false) {
            if (pendingRequests === 0) {
                await store.dispatch('startLoading')
            }
            pendingRequests++
        }

        let token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        return config
    },
    async (error) => {
        return Promise.reject(error)
    }
)

http.interceptors.response.use(
    async (response) => {
        pendingRequests--
        if (pendingRequests === 0) {
            await store.dispatch('stopLoading')
        }
        return response
    },
    async (error) => {
        pendingRequests--
        if (pendingRequests === 0) {
            await store.dispatch('stopLoading')
        }

        if (error.response) {
            switch (error.response.status) {
                case 401:
                    store.dispatch('logout').then(() => {
                        router.push('/login')
                    })
                    break
                default:
                    if (!error.response.data.errors) {
                        Vue.prototype.$toast.warning(error.response.data.message)
                    }
                    break
            }
        }

        return Promise.reject(error)
    }
)

export default http
